import React from 'react'

const Preloader = () => {
  return (
    <div className="progress">
    <div className="indeterminate"></div>
</div>
  )
}
export default Preloader
