import React from 'react'
const Logo = props => (<svg
  className={props.className}
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 155.211 164.178"
  enableBackground="new 0 0 155.211 164.178"
  >
  <g>
  <path fillRule="evenodd" clipRule="evenodd" d="M135.108,115.328V74.972c0-3.536-1.23-5.304-3.689-5.304
  c-2.46,0-3.69,1.768-3.69,5.304v40.356c0,3.536,1.23,5.304,3.69,5.304C133.877,120.632,135.108,118.864,135.108,115.328
  L135.108,115.328z M80.634,0c24.697,1.456,47.087,21.982,47.087,46.949c0,0.201-0.003,0.401-0.007,0.602h0.014v7.243
  c1.23,0,2.46,0,3.69,0c6.687,0,12.145,1.844,16.372,5.535c5.228,4.535,7.842,11.568,7.842,21.1v29.057
  c0,9.532-2.614,16.565-7.842,21.101c-4.228,3.689-9.686,5.534-16.372,5.534h-24.215V47.788h-0.019
  c0.009-0.279,0.016-0.558,0.016-0.839c0-13.699-13.185-25.033-26.566-26.438V0L80.634,0z"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M27.903,115.328V74.972c0-3.536-1.23-5.304-3.689-5.304
  c-2.46,0-3.69,1.768-3.69,5.304v40.356c0,3.536,1.23,5.304,3.69,5.304C26.673,120.632,27.903,118.864,27.903,115.328
  L27.903,115.328z M74.45,20.576c-13.108,1.664-25.994,12.87-25.994,26.373c0,0.281,0.006,0.56,0.015,0.839h-0.043v89.333H24.214
  c-6.688,0-12.146-1.845-16.374-5.534C2.613,127.051,0,120.018,0,110.485V81.428c0-9.532,2.613-16.565,7.84-21.1
  c4.228-3.69,9.686-5.535,16.374-5.535c1.23,0,2.459,0,3.689,0V47.55h0.039c-0.004-0.2-0.007-0.401-0.007-0.602
  c0-24.773,22.086-45.176,46.515-46.913V20.576L74.45,20.576z"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M80.933,115.015c0,3.51-0.896,5.415-2.692,5.72
  c-0.082,0.008-0.166,0.012-0.252,0.012c-2.153,0-3.229-1.921-3.229-5.764V75.751h0.01V54.775H54.292v4.667
  c-0.019-0.035-0.038-0.069-0.057-0.104v56.335c0,14.298,4.65,21.446,13.952,21.446h9.015v0.032h10.304
  c9.301,0,13.951-7.149,13.951-21.446V75.751h0.013V54.775H80.93C80.93,74.856,80.933,94.93,80.933,115.015L80.933,115.015z"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M3.648,164.012v-3.683h9.439c1.521,0,2.535-0.157,3.036-0.455
  c0.723-0.43,1.091-1.086,1.091-1.954c0-0.999-0.429-1.741-1.272-2.198c-0.479-0.26-1.274-0.394-2.322-0.394H9.788
  c-2.343,0-4.038-0.428-5.064-1.272c-0.643-0.529-1.157-1.216-1.516-2.022c-0.358-0.805-0.543-1.681-0.543-2.602
  c0-1.453,0.427-2.791,1.272-3.982c0.818-1.155,2.16-1.847,3.982-2.027c0.607-0.061,1.537-0.094,2.757-0.094h10.262v3.683h-9.258
  c-1.387,0.011-2.315,0.075-2.757,0.181c-0.927,0.224-1.396,0.937-1.396,2.106c0,0.987,0.394,1.66,1.169,2.001
  c0.516,0.227,1.391,0.347,2.606,0.347h3.315c1.697,0,2.974,0.156,3.807,0.439c1.457,0.495,2.475,1.373,3.015,2.607
  c0.435,0.993,0.667,2.068,0.667,3.211c0,1.252-0.296,2.386-0.864,3.383c-0.801,1.406-2.032,2.266-3.651,2.529
  c-0.784,0.128-1.983,0.196-3.548,0.196H3.648L3.648,164.012z M37.014,147.011H30.42v-3.683h17.839v3.683h-6.848v17.001h-4.397
  V147.011L37.014,147.011z M61.999,143.328v12.394c0,0.993,0.091,1.771,0.244,2.312c0.151,0.54,0.446,1.028,0.833,1.401
  c0.87,0.842,2.089,1.272,3.636,1.272c0.988,0,1.859-0.177,2.587-0.502c0.729-0.326,1.245-0.787,1.531-1.36
  c0.292-0.584,0.439-1.629,0.439-3.123v-12.394h4.396v12.181c0,1.608-0.176,2.945-0.502,3.987c-0.326,1.041-0.864,1.905-1.572,2.529
  c-1.618,1.429-3.947,2.152-6.957,2.152c-3.191,0-5.535-0.709-6.998-2.105c-0.765-0.729-1.307-1.564-1.593-2.456
  s-0.439-2.162-0.439-3.786v-12.502H61.999L61.999,143.328z M86.985,143.328h8.777c2.193,0,3.897,0.226,5.09,0.652
  c2.206,0.789,3.697,2.276,4.427,4.422c0.559,1.641,0.849,3.419,0.849,5.307c0,1.867-0.28,3.638-0.802,5.286
  c-0.533,1.688-1.442,2.953-2.685,3.744c-0.78,0.497-1.657,0.851-2.586,1.015c-0.924,0.162-2.379,0.258-4.293,0.258h-8.777V143.328
  L86.985,143.328z M91.38,147.011v13.318h4.382c1.919,0,3.262-0.436,3.998-1.287c0.448-0.52,0.828-1.286,1.097-2.25
  c0.27-0.966,0.418-2.012,0.418-3.114c0-1.21-0.172-2.336-0.49-3.347c-0.322-1.017-0.771-1.789-1.314-2.275
  c-0.775-0.693-2.038-1.045-3.708-1.045H91.38L91.38,147.011z M116.547,164.012v-20.684h4.396v20.684H116.547L116.547,164.012z
  M141.579,143.194c2.061-0.003,3.896,0.356,5.457,1.06c1.841,0.83,3.146,2.257,3.879,4.226c0.577,1.551,0.88,3.384,0.88,5.488
  c0,2.1-0.357,3.931-1.076,5.467c-0.866,1.854-2.245,3.158-4.076,3.879c-1.368,0.539-2.958,0.818-4.743,0.818
  c-2.327,0-4.356-0.442-6.077-1.304c-1.441-0.723-2.56-1.873-3.315-3.414c-0.756-1.544-1.143-3.435-1.143-5.648
  c0-3.936,0.981-6.753,2.938-8.421C135.953,143.938,138.392,143.198,141.579,143.194L141.579,143.194z M141.61,146.68
  c-2.027,0-3.491,0.67-4.365,1.986c-0.68,1.023-1.029,2.685-1.029,4.965c0,2.427,0.439,4.213,1.304,5.349
  c0.85,1.117,2.229,1.681,4.106,1.681c1.814,0,3.169-0.574,4.024-1.701c0.858-1.13,1.292-2.896,1.292-5.281
  c0-2.162-0.354-3.816-1.044-4.955C145.084,147.381,143.643,146.68,141.61,146.68L141.61,146.68z"/>
  </g>
  </svg>)
const RayIcon = props => (
    <svg
    className={props.className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 499 73"
    enableBackground="new 0 0 499 73"
    xmlSpace="preserve"
    >
    <polygon points="0,0 0,68.243 214.713,49.942 214.713,73.237 499.333,44.11 266.314,34.124 266.314,18.311 " />
    </svg>
)

const FacebookLogo = props => (
      <svg
      className={props.className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="199px"
      height="200px"
      viewBox="144.75 0 199 200"
      enableBackground="new 144.75 0 199 200"
      xmlSpace="preserve"
      >
      <path
      d="M326.33,2H162.17c-9.19,0-16.42,7.22-16.42,16.42v164.17c0,9.19,7.22,16.42,16.42,16.42h75.52h14.55v-64.67h-26.27v-26.27
      h26.27V85.08c0-18.71,14.45-34.47,32.83-36.12h29.55v26.27h-26.27c-5.58,0-9.85,4.27-9.85,9.85v22.98h32.83v26.27H278.5V199h47.83
      c9.19,0,16.42-7.22,16.42-16.42V18.42C342.75,9.22,335.53,2,326.33,2z"
      />
      </svg>
)
const InstagramLogo = props => (
        <svg
        className={props.className}
        x="0px"
        y="0px"
        width="288px"
        height="288px"
        viewBox="96 96 288 288"
        enableBackground="new 96 96 288 288"
        xmlSpace="preserve"
        >
        <path d="M240,176c-35.35,0-64,28.65-64,64s28.65,64,64,64c35.33-0.04,63.96-28.67,64-64C304,204.65,275.35,176,240,176z" />
        <path
        d="M366.21,115.49L366.21,115.49L366.21,115.49c-6.92-7.37-16.73-11.98-27.63-11.99H141.42c-10.9,0.01-20.71,4.62-27.63,11.99
        h0v0c-6.37,6.78-10.27,15.89-10.29,25.93v197.17c0.01,10.03,3.92,19.15,10.29,25.93v0h0c6.92,7.36,16.73,11.97,27.63,11.99h197.17
        c10.9-0.01,20.71-4.62,27.63-11.99h0v0c6.37-6.78,10.27-15.89,10.29-25.93V141.42C376.49,131.38,372.58,122.27,366.21,115.49z
        M240,320c-44.18,0-80-35.82-80-80s35.82-80,80-80c44.16,0.05,79.95,35.84,80,80C320,284.18,284.18,320,240,320z M329,158.87
        c-6,0-10.87-4.87-10.87-10.87S323,137.13,329,137.13s10.87,4.87,10.87,10.87C339.87,154,335.01,158.87,329,158.87z"
        />
        </svg>
)
const YoutubeLogo = props => (
          <svg
          className={props.className}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="144.75 0 199 200"
          enableBackground="new 144.75 0 199 200"
          xmlSpace="preserve"
          >
          <path
          d="M327.17,17.67H161.33c-9.29,0-16.58,6.09-16.58,13.83v138.33c0,7.75,7.3,13.83,16.58,13.83h76.28h39.8h49.75
          c9.29,0,16.58-6.09,16.58-13.83V31.5C343.75,23.75,336.45,17.67,327.17,17.67z M283.03,104.59l-64.08,35.39
          c-0.67,0.33-1.01,0.33-1.69,0.33c-0.68,0-1.01,0-1.69-0.33c-1.01-0.67-1.69-1.67-1.69-3.01V64.52c0-1.34,0.67-2.34,1.69-3.01
          c1.01-0.67,2.36-0.67,3.37,0l64.08,37.06c1.01,0.67,1.69,1.67,1.69,3C284.72,102.92,284.04,103.92,283.03,104.59z"
          />
          </svg>
)

const PlayIcon = props => (
            <svg
            className={props.className}
            version="1.1"
            x="0px"
            y="0px"
            viewBox="52.237 52.5 102.485 103"
            enableBackground="new 52.237 52.5 102.485 103"
            xmlSpace="preserve"
            >
            <path
            d="M141.029,108.59L76.95,143.98
            c-0.671,0.33-1.011,0.33-1.69,0.33s-1.01,0-1.689-0.33c-1.01-0.67-1.69-1.67-1.69-3.01V68.52c0-1.34,0.67-2.34,1.69-3.01
            c1.01-0.67,2.359-0.67,3.369,0l64.08,37.061c1.01,0.67,1.69,1.67,1.69,3C142.721,106.92,142.04,107.92,141.029,108.59z"
            />
            </svg>
)

const WhatsIcon = props => (
              <svg
              className={props.className}
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              >
              <path
              d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
              C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
              c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
              c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
              c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
              c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
              c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
              c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
              c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
              />{' '}
              </svg>
)
const PhoneSkullIcon = props => (
                <svg
                className={props.className}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="185.79 42.67 131.52 130.98"
                xmlSpace="preserve"
                >
                <path
                fill="none"
                d="M234.96,105.45l-4.27,0.29c-2.7,0.18-5.12,1.41-6.81,3.46c-1.7,2.05-2.45,4.65-2.13,7.33l0.34,2.83
                c0.49,4.06,3.13,6.69,6.75,6.69c1.28,0,2.59-0.34,3.9-1l3.71-1.88c4.87-2.48,8.69-7.4,8.69-11.19
                C245.13,108.01,240.67,105.07,234.96,105.45z"
                />
                <path
                fill="none"
                d="M272.62,105.74l-4.27-0.29c-5.69-0.37-10.17,2.56-10.17,6.53c0,3.8,3.82,8.71,8.69,11.19l3.71,1.88
                c1.31,0.67,2.62,1,3.9,1c3.61,0,6.26-2.63,6.75-6.69l0.34-2.83c0.32-2.68-0.43-5.29-2.13-7.33
                C277.73,107.15,275.32,105.93,272.62,105.74z"
                />
                <path
                fill="none"
                d="M251.06,121.74c-4.29,0-6.65,10.94-6.65,14.57c0,1.54,0.37,2.56,1.16,3.21c0.8,0.65,1.85,0.74,2.78,0.74
                c0.39,0,0.8-0.01,1.23-0.03c0.92-0.04,2.01-0.04,2.94,0c0.44,0.02,0.85,0.03,1.23,0.03c1.95,0,3.95-0.47,3.95-3.94
                C257.71,132.68,255.35,121.74,251.06,121.74z"
                />
                <path d="M295.51,145.51" />
                <g>
                <path
                d="M252.75,54.97h-3.72c-25.17,0-38.39,18.69-38.39,41.66v23.73c0,7.66,5.47,16.99,5.58,17.02l4.88,0.55l13.11,2.35
                c0.79,0.27,1.36,1,1.36,1.84v6.75c0,1.16,0.7,2.01,1.76,2.62c0.13-0.94,1.03-1.66,2.01-1.66c1.07,0,2.04,0.87,2.04,1.94v0.87
                c0,0.06,0.74,0.1,1.19,0.1h2.68v-0.97c0-1.07,0.87-1.93,1.94-1.93s1.94,0.87,1.94,1.93v0.97h3.87v-0.97c0-1.07,0.87-1.93,1.94-1.93
                c1.07,0,1.93,0.87,1.93,1.93v0.97h2.04c0.5,0,0.86-0.03,1.83-0.08v-0.89c0-1.07,0.78-1.94,1.85-1.94c1.05,0,1.85,0.83,1.89,1.88
                c1.11-0.58,1.96-1.46,1.96-2.83c0-0.12,0.11-0.24,0.11-0.35v-6.4c0-0.83,0.43-1.57,1.22-1.84c0,0,16.47-2.51,17.72-2.91
                c0.27-0.09,7-9.35,7-17.02V96.63C292.48,73.66,277.92,54.97,252.75,54.97z M236.26,123.17l-3.71,1.88c-1.31,0.67-2.62,1-3.9,1
                c-3.61,0-6.26-2.63-6.75-6.69l-0.34-2.83c-0.32-2.68,0.44-5.29,2.13-7.33c1.69-2.04,4.11-3.27,6.81-3.46l4.27-0.29
                c5.71-0.38,10.17,2.56,10.17,6.53C244.95,115.77,241.13,120.69,236.26,123.17z M253.59,140.25c-0.38,0-0.8-0.02-1.23-0.03
                c-0.93-0.04-2.01-0.04-2.94,0c-0.44,0.02-0.85,0.03-1.23,0.03c-0.93,0-1.99-0.09-2.78-0.74c-0.79-0.65-1.16-1.67-1.16-3.21
                c0-3.63,2.36-14.57,6.65-14.57c4.29,0,6.65,10.94,6.65,14.57C257.53,139.79,255.54,140.25,253.59,140.25z M281.38,116.53
                l-0.34,2.83c-0.49,4.07-3.13,6.69-6.75,6.69c-1.28,0-2.59-0.34-3.9-1l-3.71-1.88c-4.87-2.48-8.69-7.39-8.69-11.19
                c0-3.97,4.47-6.9,10.17-6.53l4.27,0.29c2.7,0.18,5.12,1.41,6.81,3.46C280.95,111.25,281.71,113.85,281.38,116.53z"
                />
                <path
                d="M311.19,89.83h-0.25v-0.99c0-2.37-1.71-4.3-3.83-4.3h-1.77c-5.61-24.14-28.18-41.87-54.14-41.87
                c-25.96,0-48.52,17.73-54.13,41.87h4.43c5.27-22.7,25.75-38.89,49.76-38.89c24,0,44.48,16.19,49.76,38.89h-1.57
                c-2.11,0-3.83,1.92-3.83,4.3v34.38c0,2.37,1.71,4.3,3.83,4.3h7.66c2.11,0,3.83-1.92,3.83-4.3v-0.97h0.25
                c3.38,0,6.12-3.11,6.12-6.95V96.77C317.31,92.94,314.57,89.83,311.19,89.83z"
                />
                <path
                d="M259.9,162.18h-18.44c-2.34,0.01-4.42,1.54-5.2,3.82h-23.53c-7.13,0-12.91-5.99-12.91-13.38v-25.1h3.83
                c2.11,0,3.83-1.92,3.83-4.3V88.85c0-2.37-1.71-4.3-3.83-4.3H196c-2.11,0-3.83,1.92-3.83,4.3v0.99h-0.26
                c-3.38,0-6.12,3.11-6.12,6.95v18.53c0,3.84,2.74,6.95,6.12,6.95h0.26v0.97c0,2.37,1.71,4.3,3.83,4.3h0.14v25.1
                c0,9.5,7.43,17.21,16.6,17.21h23.53c0.78,2.29,2.86,3.82,5.2,3.82h18.44c3.05,0,5.53-2.57,5.53-5.74
                C265.43,164.75,262.95,162.18,259.9,162.18z"
                />
                </g>
                <path d="M194.76,126.15" />
                </svg>
)
const PriceTableRay = props => (
                  <svg
                  className={props.className}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 499 73"
                  enableBackground="new 0 0 499 73"
                  xmlSpace="preserve"
                  >
                  <polygon points="0,0 0,68.243 214.713,49.942 214.713,73.237 499.333,44.11 266.314,34.124 266.314,18.311 " />
                  </svg>
)
const ScrollArrow = props => (
                    <svg
                    className={props.className}
                    version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 -77.5 128 205.5" enableBackground="new 0 -77.5 128 205.5" space="preserve">
                    <g>
                    <polygon points="103.347,62.395 70.013,95.728 70.013,9.787 58.013,9.787 58.013,95.728 24.679,62.395 16.193,70.88
                    64.013,118.698 111.832,70.88"/>
                    <rect x="58.017" y="-17.201" width="11.983" height="11.648"/>
                    <rect x="58.017" y="-42.388" width="11.983" height="11.648"/>
                    <rect x="58.017" y="-66.576" width="11.983" height="11.648"/>
                    </g>
                    </svg>
)
export {
  Logo,
  RayIcon,
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
  PlayIcon,
  WhatsIcon,
  PhoneSkullIcon,
  PriceTableRay,
  ScrollArrow
}
